import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PaySuccess from './pages/Paysuccess';
import Pay from './pages/Pay';
import PayFail from './pages/PayFail';
import Login from './pages/Login';
import Home from './pages/Home';
import Test from './pages/Test.js';
import Activities from './pages/Activities.js';
import Transactions from './pages/Transactions';

 function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>} >
        <Route path="/" element={<Pay/>} />
        <Route path="/success" element={<PaySuccess/>} />
        <Route path="/fail" element={<PayFail/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/test" element={<Test/>} />
        <Route path="/activities" element={<Activities/>} />
        <Route path="/transactions" element={<Transactions/>} />
      </Route>
      
    </Routes>
  );
}

export default App;
import { configureStore } from "@reduxjs/toolkit";
import language  from "./language";
import currency  from "./currency";
import filter  from "./filter";
import transaction from './transactionSlice'; 

const store = configureStore({
    reducer : {
     language,
     currency,
     filter,
     transaction
    }
})

export default store;
import React from 'react'
import {Navigate } from 'react-router-dom';


class TryAgainNavigate extends React.Component {   
  
    render() {
      let navigate;
      if (localStorage.getItem("tryAgain") == 1) {
        navigate =  <Navigate to="/" replace={true}  />;
        localStorage.setItem("tryAgain" , 0) 

      }    
  
      return (
        <div>          
          {navigate}
        </div>
      );
    }
  }

  export default TryAgainNavigate;
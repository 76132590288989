import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    
    currentLang : { 
        value: localStorage.getItem('currentLangValue') ,
        label: localStorage.getItem('currentLangDescription') 
    },


}

const languages = createSlice({
    name:'languages',
    initialState,
    reducers : {
        setLanguage : (state , action) => {
            state.currentLang = action.payload
            localStorage.setItem("i18nextLng" , action.payload.value)
            localStorage.setItem("currentLangValue" , action.payload.value)
            localStorage.setItem("currentLangDescription" , action.payload.label)
        },
    }

})

export const {setLanguage} = languages.actions

export default languages.reducer;
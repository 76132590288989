
import i18n from 'i18next';
import locales from './locales/index';
import { initReactI18next   } from "react-i18next";
import { useSelector } from 'react-redux';
import { setLanguage} from '../../stores/index'

var userLanguage = localStorage.getItem("i18nextLng") || null

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'tr',
    interpolation: {
      escapeValue: false,
    },
    lng:  userLanguage || "tr",
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    },
    resources: locales
  });

export default i18n;
import { createSlice } from "@reduxjs/toolkit";

const transactionSlice = createSlice({
    name: 'transaction',
    initialState: {
      data: [],
      filteredData: []
    },
    reducers: {
      setData: (state, action) => {
        state.data = action.payload;
        state.filteredData = action.payload; 
      },
      setFilteredData: (state, action) => {
        state.filteredData = action.payload;
      }
    },
  });
  
  export const { setData, setFilteredData } = transactionSlice.actions;
  
  // Selectors
  export const selectData = state => state.transaction.data;
  export const selectFilteredData = state => state.transaction.filteredData;
  
  export default transactionSlice.reducer;
  
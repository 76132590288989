import axios from "axios";

function request(endpoint, data = false, method = "GET" ) {
  
    const baseUrl = localStorage.getItem("gatewayHost");
    return new Promise(async (resolve, reject) => {  

    const config = {
        headers:{ 
          'tenantid': 'B4BE8AF7-4F6E-490E-B05D-57839BA3B32E', 
          'Authorization': "Bearer " +  localStorage.getItem("gatewayAuthToken")
          }
    };
    
    if (data && method === "POST") {
            axios.post(baseUrl + endpoint, data , config).then(response => {
                resolve(response);
                }).catch(error => {
                  
                resolve(error);
              });
          }
          else{
            axios.get(baseUrl + endpoint, config).then(response => {
                resolve(response);
        })
            .catch(error => {
              console.log(error)
                resolve(error);
            });
          }      
    });
  }
  
  export const getPayGate = (endpoint) => request(endpoint , false , "GET" );
  export const postPayGate = (endpoint, data ) => request(endpoint, data, "POST" );
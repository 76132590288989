import React, { useEffect, useState } from 'react'
import './../Css/successPage.css'
import i18n from '../plugins/i18n';


export default function PaySuccess() {

  const [educationPeriod, setEducationPeriod] = useState('');

    useEffect(() => {
        // Eğitim dönemi bilgisini alma
        const storedEducationPeriod = localStorage.getItem("educationPeriod");

        // Eğitim dönemi bilgisini kontrol etme ve -1 karakterini kesme
        if (storedEducationPeriod && storedEducationPeriod.endsWith("-1") || storedEducationPeriod && storedEducationPeriod.endsWith("-2")) {
            setEducationPeriod(storedEducationPeriod.slice(0, -2)); // Son 2 karakteri keser
        } 
        else if (storedEducationPeriod && storedEducationPeriod.endsWith("-12"))
        {
          setEducationPeriod(storedEducationPeriod.slice(0, -3)); // Son 3 karakteri keser
        }
        else {
            setEducationPeriod(storedEducationPeriod);
        }
    }, []);


  return (
    <>
    <div className='successbody'>
      <div className="card">
      <div className="Last">
        <i className="i-line">✓</i>
      </div>
        <h1 className='h1-line'>Ödeme İşlemi Başarılı</h1> 
        <p className='p-line'> Referans Numarası : {localStorage.getItem("orderReference")}</p>
        <p className='p-line'> Provizyon Numarası : {localStorage.getItem("provisionNumber")}</p>
        <p className='p-line'> Ödeme Tutarı : {localStorage.getItem("processAmount")}</p>
        <p className='p-line'> Öğrenci TCKN : {localStorage.getItem("studentTckn")}</p>
        <p className='p-line'> Öğrenci Ad Soyad : {localStorage.getItem("studentName")} {localStorage.getItem("studentSurname")}</p>
        <p className='p-line'> Eğitim Dönemi : {educationPeriod}</p>
        <p className='p-line'>Yapmış oldugunuz ödeme bilgileri<br/> MEF Okulları Muhasebe departmanına iletilmiştir.</p>
        <div className='form-actions'>             
                <div className='result-container'>
                 <button className='cancel-button' ><a href='https://www.mef.k12.tr/'>{i18n.t('btn.backwebsite')}</a></button> 
                </div> 
              </div>
        </div>
        </div>
     </>
  )
}
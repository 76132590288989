export function maskCardNumber(value) {
    return `${value.substr(0, 6)}${new Array(value.length - 10).fill('*').join('')}${value.substr(12, 16)}`
  };
  
export const removeSpaces = value => value.replace(/\s/g, '') || '';

export function phoneNormalizer(value) {
  const inAcceptedCharacters = ['+', '(', ')', ' ', '_'];
  inAcceptedCharacters.forEach(char =>
    value = value?.split(char).join('')
  )
  if (value?.startsWith('9')) value = value.substring(1);
  if (value?.startsWith('0')) value = value.substring(1);

  return value;
}

export function formatCVC(value, allValues = {}) {
  const clearValue = clearNumber(value);
  const maxLength = getCVCLength(allValues);
  return clearValue.slice(0, maxLength);
}

export function getCVCLength(allValues = {}) {
  return 3;
}

export function classNames(classNamesList = []) {
  return classNamesList.filter(x => x !== false && x !== undefined && x !== null && x !== '' && x !== 'false').join(' ').trim()
}

export function formatCreditCardNumber(value) {
  if (!value)
    return value;
    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;
  
    switch (issuer) {
      case 'amex':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
        break;
      case 'dinersclub':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }
  
    return nextValue.trim();
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);
  if (clearValue.length >= 3)
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;

  return clearValue;
}

export function validateCC(rule, value, callback) {
  const { len, length } = getCCLength({ cardNumber: value });
  if (!value)
    callback(i18n.t('msg.required'))
  if (value?.length < len)
    callback(i18n.t('msg.reqLength', { length }))
  else callback();
}
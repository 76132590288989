import React, { useState } from "react";
import { NavLink, Outlet } from 'react-router-dom'
import Select from 'react-select'
import { useSelector , useDispatch } from 'react-redux';
import { setLanguage   } from '../stores/language';
import { setCurrency   } from '../stores/currency';
import i18n from '../plugins/i18n';
import '../styles.css'
import CustomNavBar from '../Components/CustomNavbar';
import Footer from '../Components/Footer';
import FooterData from '../Components/FooterData';
import logo from '../images/logo.png';


export default function home() {

  const currentLang = useSelector(state => state.language.currentLang)
  const currentCurrency = useSelector(state => state.currency.currentCurrency)
  const dispatch = useDispatch();
  const [langLabel, setlangLabel] = useState(i18n.t('msg.selectLanguage'));
  const [currencyLabel, setCurrencyLabel] = useState(i18n.t('msg.selectCurrency'));

  const languageList =  [
    { value: 'tr', label: i18n.t('slct.langTr') },
    { value: 'en', label: i18n.t('slct.langEn') }
  ];

  const currencyList =  [
        { value: '8E6CC739-83E0-4AF5-ACCF-A55476E758C6', label: 'TRY' , symbol : '₺' , code : 'YT' },
        { value: '154EF78A-E03B-4825-B2D1-41A92D02EF61', label: 'USD' , symbol : '$' , code : 'US'},
        { value: '97652D42-C24A-4524-B26D-164307ABD700', label: 'EUR', symbol : '€'  , code : 'EU'},
  ];

  const languageChanged = (e) => {
    console.log(e.value);
    dispatch(setLanguage(e))
    console.log(currentLang)
    setlangLabel(i18n.t('msg.selectLanguage'))
    console.log(langLabel)
    console.log(localStorage.getItem("i18nextLng") )
    //i18n.changeLanguage(e.value)    
    window.location.reload(false);
    
  }

  const currencyChanged = (e) => {
    console.log(e);
    dispatch(setCurrency(e))
    console.log(currentLang)
    setCurrencyLabel(i18n.t('msg.selectLanguage'))
    console.log(langLabel)
    console.log(localStorage.getItem("i18nextLng") )
    //i18n.changeLanguage(e.value)    
    window.location.reload(false);
    
  }

  return ( 
    <>
    <header>               
    <CustomNavBar cClass="custom_container p0" hbtnClass="new_btn" 
         currentLang = {currentLang}
         langLabel  = {langLabel}
         languageList  = {languageList}
         languageChanged = {languageChanged}
         currencyList = {currencyList}
         currentCurrency = {currentCurrency}
         currencyChanged = {currencyChanged}
          />
    </header>
    <div className='header-col-logo-middle'>
            <img
            src={logo}
            alt="mef logo"
            className="header-col-logo-middle-img"
          />
    </div>
     <div className="body_wrapper">
      
         
    </div>
    <div>
        <Outlet></Outlet>
    </div>
    <footer>
    <h3 className="footer-h3">{i18n.t('ftr.copywrite')}</h3>
    </footer>     
    </>
   
  
  )
}

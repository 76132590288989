import React, {Component , useState} from 'react';
import Select from 'react-select';
import i18n from '../plugins/i18n';
import '../styles.css'


class CustomNavbar extends Component {

    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass , langLabel , languageList , languageChanged , currentLang , currencyList ,  currentCurrency , currencyChanged } =this.props;
        console.log(this.props)
        console.log(langLabel)
        console.log(currentLang)

        const customStyles = {
            singleValue : (base,state) => ({
               ...base,
               color:'white',

            } ),
            option: (base, state) => ({
                ...base,
                color: 'black',
                background:'white',
                width:'150px',
                textAlign:'center',
                position:'flex',
              }),
            control: (base, state) => ({
              ...base,
              background: "#212D65",
              // match with the menu
              borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
              // Overwrittes the different states of border
              borderColor:  "#212D65",
              // Removes weird border around container
              boxShadow: state.isFocused ? null : null,
              "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "#212D65" : "white"
              },
              color:'white',
              width:'150px'     ,
              height:'1.5rem'       
            }),
            menu: base => ({
              ...base,
              // override border radius to match the box
              borderRadius: 0,
              // kill the gap
              marginTop: 0,
              width:'150px'   
            }),
            menuList: base => ({
              ...base,
              // kill the white space on first and last option
              padding: 0,
              
              width:'150px'  
            })
          };
        
       

        return (
      
          <>
  <div className='row-header'>
                <div >
<Select 
                options= {languageList}
                placeholders={{ name: i18n.t('lbl.selectLanguage') }}
                id="languageselect" 
                name='languageselect'     
                onChange={languageChanged}      
                value= {(currentLang === null || currentLang.value === null) ? {value: 'tr', label: i18n.t('slct.langTr')} : currentLang }
                styles={customStyles}
                /> 

</div>
<div >
{/* <Select 
                options= {currencyList}
                placeholders={{ name: i18n.t('lbl.selectCurrencies') }}
                id="currencyselect" 
                name='currencyselect'     
                onChange={currencyChanged}      
                value= {(currentCurrency === null || currentCurrency.value === null) ? {value: '8E6CC739-83E0-4AF5-ACCF-A55476E758C6', label: 'TRY' , symbol : '₺'} : currentCurrency }
                styles={customStyles}
                />  */}
</div>

          </div>
          
                
             
          </>
            
      
        )
    }
} 


export default CustomNavbar;
import React from 'react'
import './../Css/successPage.css'
import i18n from '../plugins/i18n';
import {Navigate } from 'react-router-dom';
import TryAgainNavigate from '../Components/TryAgainNavigate'
import { ClearGlobal  } from '../Data/PaymentState';


export default function PayFail() {


  const tryAgain = () => {
    console.log('try againe girdi.');
    localStorage.setItem('tryAgain' , 1)      
    ClearGlobal()       
    window.location.reload() 
    
  }


    return (
        <>
          <div className='successbody'>
          <div className="card">
          <div className="Last">
            <i className="checkmark" style={{color:'red'}}>X</i>
          </div>
            <h2 style={{color:'red'}}>Ödeme İşlemi Başarısız</h2> 
            <p> Referans Numarası : {localStorage.getItem("orderReference")}</p>
            <p> Hata : {localStorage.getItem("errorMessage")}</p>
            <div className='form-actions'>             
                <div className='form-actions-col-spec'>
                 <button className='cancel-button' ><a href='https://www.mef.k12.tr/'>{i18n.t('btn.backwebsite')}</a></button> 
                </div>   
                <div className='form-actions-col-spec'>
                    <button className='submit-button' onClick={tryAgain} >{i18n.t('btn.tryAgain')} </button>
                </div>
              </div>
            </div>
            </div>
            <TryAgainNavigate/>
         </>
      )
}

import axios from "axios";

function request(endpoint, data = false, method = "GET" , query = "") {
    const baseUrl = `${process.env.REACT_APP_PAYMENT_API_URL}`;
  
    return new Promise(async (resolve, reject) => {
       
      const config = {
        headers:{ 
          'tenantid': 'B4BE8AF7-4F6E-490E-B05D-57839BA3B32E', 
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJFeHBpcmUiOiIyMDIyLTExLTE5VDIwOjEzOjM2Ljg3MDI0NDQrMDM6MDAiLCJUb2tlbkVudGl0eVVJZCI6ImFkNWQ1Y2M0LWRlOGUtNDVlNS1hZjVkLTZjN2FiYmM0Y2U0OSIsIldlYlNpdGVJZCI6MTExMSwiVXNlcklkIjowLCJUZW5hbnRJZCI6ImI0YmU4YWY3LTRmNmUtNDkwZS1iMDVkLTU3ODM5YmEzYjMyZSIsIkRvbWFpbiI6Im1leXBheS5jb20iLCJTY29wZSI6WyJOVEgiXX0.TcBC4xG8N51E7T4jcTGSlGaFpoQbp6yK23sW_qv1m6s'
        }
       };

      if (data && method === "POST") {
        axios.post(baseUrl + endpoint, data , config).then(response => {
            resolve(response);
            }).catch(error => {
            resolve(error.response);
          });
      }
      else{
        axios.get(baseUrl + endpoint + query, config).then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
      }      
    });
  }
  
  export const get = (endpoint , query) => request(endpoint , false , "GET" , query);
  export const post = (endpoint, data ) => request(endpoint, data, "POST" );
import { get, post } from "./request";
import { getPayGate, postPayGate } from "./requestPayGate";


export const getPayment = () => {
    return get("Payment");
  };

export const postPrepare = (data) => {
    return postPayGate("api/Prepare" , data);
  };

export const postPayment = (data) => {
    return post("Payment" , data);
};

export const paymentResult = (data) => {
  return get("Payment/PaymentResult" , "?RefId="+ data);
};

export const getTransaction = () => {
  return get("Transaction");
};

export const getExcelTransaction = (data) => {
  return get("Transaction/GetExcel", "?date="+ data);
};

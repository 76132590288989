export default {
  tr: {
    dev: {
      message: {
        loadingComponentChildren: "Loading component'i alt öğeler içermelidir",
        loadingComponentWrapperType: "Loading component'inin Wrapper prop'u boolean olamaz, uygun bir kapsayıcı component ya da string olarak html elementi olmalıdır",
        errorEditableTableColumnType: 'Henüz desteklenmeyen bir element tipi ile düzenlenebilir satır oluşturmaya çalıştınız. Hata kaynağı: {{errorSource}}({{errorKey}}, element tipi: {{type}})',
        errorEditableTableColumn: 'Şimdilik yalnızca render metodu ile üretilmeyen kolonlarda düzenlenebilir satır kullanılabilmektedir. Hataya sebep olan kolon: {{errorSource}}({{errorKey}})',
        withTotalNoFieldsError: 'Toplam satırı için en az bir alan gönderilmelidir',
        failedAnnouncements: "Duyurular ile ilgili bir sorun ile karşılaşıldı.",
        doesntHaveWebSocketConnection: "Websocket bağlantısı bulunamadı.",
        shutDownWebSocket: "Websocket bağlantısı kapatıldı."
      }
    }
  },
  en: {
    dev: {
      message: {
        loadingComponentChildren: "Loading component must contains children",
        loadingComponentWrapperType: "The Wrapper prop of the Loading component cannot be boolean, it must have an appropriate html element as string or container component.",
        errorEditableTableColumnType: 'You tried to create an editable row with an element type that is not yet supported. Error source: {{errorSource}}({{errorKey}}, element type: {{type}})',
        errorEditableTableColumn: 'For now, editable rows can only be used in columns that are not produced with the render method. The column that caused the error: {{errorSource}}({{errorKey}})',
        withTotalNoFieldsError: 'At least one area should be sent to the total line',
        failedAnnouncements: "Something went error in announcements.",
        doesntHaveWebSocketConnection: "Doesn't have websocket connection",
        shutDownWebSocket: "Websocket connection closed"
      }
    }
  }
}
// components/FilterComponent.js

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem, FormControl, InputLabel , Button, TextField, Grid, Box } from '@mui/material';
import { DatePicker } from 'antd';
import ExportExcel from './ExcelExport';
import { setFilter } from '../stores/filter';
import { selectData, setFilteredData } from '../stores/transactionSlice';
import {SchoolListData , CampusListData , ClassListData , PeriodListData, ActivitiesBranchData, ActivitiesCategoryData} from '../Data/SelectDataStorage'
import { useEffect , useState } from 'react';
import { width } from '@mui/system';

const FilterComponent = (props) => {
    
    
  const filters = useSelector(state => state.filter.currentFilter);
  const dispatch = useDispatch(); 
  const apiURL = process.env.REACT_APP_DOC_API_URL;

  const data = useSelector(selectData);
  
  const [campuses, setCampuses] = useState([]);
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [activityBranches, setActivityBranches] = useState([]);
  const [activityCategories, setActivityCategories] = useState([]);

    useEffect(() => {
        setCampuses(CampusListData());
        setSchools(SchoolListData());
        setClasses(ClassListData());
        setPeriods(PeriodListData());
        setActivityBranches(ActivitiesBranchData());
        setActivityCategories(ActivitiesCategoryData());
    }, []);

        const containsInFields = (data, searchString) => {
        // Küçük harfli haliyle karşılaştırma yapmak için searchString'i küçük harfe çevir
            const searchStr = searchString.toLowerCase();
            // Belirtilen alanlarda arama yap
            return [
            data.email,
            data.phone,
            data.description,
            data.vPosErrorMessage,
            data.studentNameSurName,
            data.payerTckn,
            data.studentTckn,
            data.provisionNumber
            ].some(field => field && field.toLowerCase().includes(searchStr));
    };




  const applyFilter = () => {
    let filteredData = data;

    
    const selectFields = [
        'transactionStatus', 
        'campus', 
        'school', 
        'class', 
        'educationPeriod', 
        'activityCategory', 
        'activityBranch'
    ];

    selectFields.forEach(field => {
        const filterValue = filters[field];
        if (filterValue && filterValue !== 'Seçiniz') {
            filteredData = filteredData.filter(row => row[field] === filterValue);
        }
    });    

    if (filters.transactionStatus && filters.transactionStatus  != 'Seçiniz') {
        filteredData = filteredData.filter(row => row.transactionStatus === filters.transactionStatus);
    }
    if (filters.startdate) {
        filteredData = filteredData.filter(row => gununBaslangici(parseDate(row.transactionDate)) >= gununBaslangici(new Date(filters.startdate)));
    }
    if (filters.endDate) {
        console.log(filters.endDate);
        filteredData = filteredData.filter(row => gununBaslangici(parseDate(row.transactionDate)) < gununBaslangici(new Date(filters.endDate)));
    }
    if (filters.fullTextSearch != null  && filters.fullTextSearch  != '') {
        filteredData = filteredData.filter(row => containsInFields(row, filters.fullTextSearch));
    }


    dispatch(setFilteredData(filteredData));
};

function parseDate(dateStr) {
    const [day, month, year] = dateStr.split(".");
    return new Date(year, month - 1 , day);
  }

  function gununBaslangici(tarih) {
    return new Date(tarih.getFullYear(), tarih.getMonth(), tarih.getDate());
  }

  const buttonStyle = {
    height: 38,
    width: 200,
    // Diğer ortak stil özelliklerini buraya ekleyebilirsiniz
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target; 
    dispatch(setFilter({ [name]: value }));
  };

  const handleStartDateChange = ( date) => {
    if(date != '' && date != null)
    {
        dispatch(setFilter({ startdate: date.toISOString().split("T")[0]}));
    }
    else
    {
        dispatch(setFilter({ startdate: ''}));
    }
};
const handleEndDateChange = ( date) => {
    if(date != '' && date != null)
    {
        dispatch(setFilter({ endDate : date.toISOString().split("T")[0]}));
    }
    else
    {
        dispatch(setFilter({ endDate: ''}));
    }
};

  return (
    <Box border={1} borderColor="grey.300" borderRadius={4} p={1} m={1} >
      <Grid item container alignItems="center" spacing={1} xs={12} md={24}>  

      <Grid item xs={2}>
        <DatePicker
            name='startDate'
            selected={filters.startDate}
            onChange={handleStartDateChange}
            placeholder="Başlangıç Tarihi"
          />
        </Grid>
        <Grid item xs={2}>
        <DatePicker
            name='endDate'
            selected={filters.endDate}
            onChange={handleEndDateChange}
            placeholder="Bitiş Tarihi"
          />
        </Grid>
        <Grid item xs={2}>
        <FormControl variant="outlined" fullWidth>
            <InputLabel id="transactionStatus-label">Durumu</InputLabel>
            <Select
            labelId="transactionStatus-label"
            label="Durumu"
            name="transactionStatus"
            value={filters.transactionStatus ||'' }
            onChange={handleFilterChange}
            style={{ width: 220}}
            >
            <MenuItem value="">
                <em>Seçiniz</em>
            </MenuItem>
            <MenuItem value="Başarılı">Başarılı</MenuItem>
            <MenuItem value="Başarısız">Başarısız</MenuItem>
            
            </Select>
        </FormControl>
        </Grid>
        <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="campus-label">Kampüs</InputLabel>
                    <Select
                        labelId="campus-label"
                        label="Kampüs"
                        name="campus"
                        value={filters.campus || ''}
                        onChange={handleFilterChange}
                        style={{ width: '220px' }}
                    >
                          <MenuItem value="">
                            <em>Seçiniz</em>
                        </MenuItem>
                        {campuses.map((campus, index) => (
                            <MenuItem key={index} value={campus.value}>
                                {campus.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
        </Grid>
        <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="campus-label">Okul</InputLabel>
                    <Select
                        labelId="school-label"
                        label="Okul"
                        name="school"
                        value={filters.school || ''}
                        onChange={handleFilterChange}
                        style={{ width: '220px' }}
                    >
                          <MenuItem value="">
                            <em>Seçiniz</em>
                        </MenuItem>
                        {schools.map((x, index) => (
                            <MenuItem key={index} value={x.value}>
                                {x.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
        </Grid>
        <Grid item xs={2} >
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="class-label">Sınıf</InputLabel>
                    <Select
                        labelId="class-label"
                        label="Sınıf"
                        name="class"
                        value={filters.class || '' }
                        onChange={handleFilterChange}
                        style={{ width: '220px' }}
                    >
                        <MenuItem value="">
                            <em>Seçiniz</em>
                        </MenuItem>
                        {classes.map((x, index) => (
                            <MenuItem key={index} value={x.value}>
                                {x.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
        </Grid>
        <Grid item xs={2} >
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="period-label">Eğitim Dönemi</InputLabel>
                    <Select
                        labelId="period-label"
                        label="Eğitim Dönemi"
                        name="educationPeriod"
                        value={filters.educationPeriod ||''}
                        onChange={handleFilterChange}
                        style={{ width: '220px' }}
                    >
                          <MenuItem value="">
                            <em>Seçiniz</em>
                        </MenuItem>
                        {periods.map((x, index) => (
                            <MenuItem key={index} value={x.value}>
                                {x.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
        </Grid>
        <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="activityCategory-label">Etkinlik Kategorisi</InputLabel>
                    <Select
                        labelId="activityCategory-label"
                        label="Etkinlik Kategorisi"
                        name="activityCategory"
                        value={filters.activityCategory ||''}
                        onChange={handleFilterChange}
                        style={{ width: '220px' }}
                    >
                          <MenuItem value="">
                            <em>Seçiniz</em>
                        </MenuItem>
                        {activityCategories.map((x, index) => (
                            <MenuItem key={index} value={x.value}>
                                {x.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
        </Grid> 
        <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="activityBranch-label">Etkinlik Adı</InputLabel>
                    <Select
                        labelId="activityBranch-label"
                        label="Etkinlik Adı"
                        name="activityBranch"
                        value={filters.activityBranch ||''}
                        onChange={handleFilterChange}
                        style={{ width: '220px' }}
                    >
                          <MenuItem value="">
                            <em>Seçiniz</em>
                        </MenuItem>
                        {activityBranches.map((x, index) => (
                            <MenuItem key={index} value={x.value}>
                                {x.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
        </Grid>
        <Grid item xs={2}>
            <TextField
                name="fullTextSearch"
                label="Tckn , Email , Telefon , Açıklama , Hata Mesajı"
                variant="outlined"
                value={filters.fullTextSearch}
                onChange={handleFilterChange}
                placeholder="Tckn , Email , Telefon , Açıklama , Hata Mesajı"
                style={{ width: '400px' }}
            />
        </Grid>   
      </Grid>
        <Grid container spacing={5} p={1} justifyContent="flex-end" >  
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <ExportExcel  
                fileName={'OdemeHareketleriExcel'}    
                date={filters.startDate}   
                style={buttonStyle}                             
            />
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Button 
                    variant='contained' 
                    color="error"    
                    style={buttonStyle} 
                    onClick={applyFilter}>
                    Filtrele
                </Button>
            </Grid>
      </Grid>
    </Box>
  );
}

export default FilterComponent;

import React from 'react';
import Card from 'react-credit-cards';
import '../styles.css';
import 'antd/dist/antd.css';
import Select from 'react-select'
import {SchoolListData , CampusListData , ClassListData , PeriodListData} from '../Data/SelectDataStorage'
import {maskCardNumber, removeSpaces , phoneNormalizer } from '../helpers/common';
import {Link , Navigate } from 'react-router-dom';

import formatValue, {
  formatCreditCardNumber,
  formatCurrency,
  formatCVC,
  formatExpirationDate
} from '../utils'

import 'react-credit-cards/es/styles-compiled.css'
//  import 'react-phone-number-input/style.css'
import { Form, Checkbox, Row, Col, Spin, Icon, Tooltip , Modal, BackTop } from 'antd';
import { getPayment, postPrepare , postPayment , paymentResult } from '../services';
import { PaymentState , ClearGlobal  } from '../Data/PaymentState';
import CreditCard3D from '../Components/CreditCard3D';
import NavigateResult from '../Components/NavigateResult';
import i18n from '../plugins/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser  , faMoneyBill , faCreditCard , faUserFriends , faInfo , faCheck , faPhone , faMailReply } from '@fortawesome/free-solid-svg-icons'
import { Backdrop, CircularProgress } from '@mui/material';

class Pay extends React.Component {

  state = PaymentState();
  campuses = CampusListData(); 
  schools = [];
  classes = [];
  periods = PeriodListData();
  selectClassRef = null;
   
  
  componentDidMount() {    
      const { navigate } = this.props
      getPayment().then(response => {
      localStorage.setItem("paymentSessionId", response.data.paymentSessionId);  
      localStorage.setItem("gatewayAuthToken", response.data.gatewayAuthToken);  
      localStorage.setItem("gatewayHost", response.data.gatewayHost);   

      if(localStorage.getItem('currentCurrencyValue') === null || localStorage.getItem('currentCurrencyValue').length === 0)
      {
            localStorage.setItem("currentCurrencyValue" , '8e6cc739-83e0-4af5-accf-a55476e758c6')
            localStorage.setItem("currentCurrencyDescription" , 'TRY')
            localStorage.setItem("currentCurrencySymbol" , '₺')
            localStorage.setItem("currentCurrencyCode" , 'YT')
      }
      this.setState({paymentSessionId :response.data.paymentSessionId })    
      }).catch(error => {
          console.log(error);
        });;
      localStorage.setItem("transactionResult" , '');
      i18n.changeLanguage(localStorage.getItem("i18nextLng") )
      console.log(this.state.isPayable)
  }

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer })
    }
  }

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    })
  }

  goToMef = () => {
    Link("");
  }

  error = (title , message) => {    
    this.setState({loading : false});   
    Modal.error({
      title: title,
      content: message,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === 'amount'){
      const formattedValue = formatCurrency(target.value); // Raw değeri biçimlendir    
      this.setState({ [target.name]: formattedValue })
    }
    else
    {
      if (target.name === 'number') {
        target.value = formatCreditCardNumber(target.value)
      } else if (target.name === 'expiry') {
        target.value = formatExpirationDate(target.value)
      } else if (target.name === 'cvc') {
        target.value = formatCVC(target.value)
      } else if (target.name === 'phone') {
  
      }
      else if (target.name === 'cardHolderName'){
            console.log('Kart sahibi adı' + target.value)
      }
      this.setState({ [target.name]: target.value })
    }
  }

  phoneChanged = value => {
     
    this.setState({ 'phone': value })
  }

  campusChanged= selectedOption => {    
    this.schools = SchoolListData();
    this.setState({ campus : selectedOption.target.value });
    this.schools = this.schools.filter(x => x.campuses.includes(selectedOption.target.value))
    this.classes = this.classes.filter(x => x.campuses.includes(selectedOption.target.value))
  }
  schoolChanged= selectedOption => {
    this.classes = ClassListData();
    this.setState({ school : selectedOption.target.value });
    this.setState({ class : '' });        
    this.classes = this.classes.filter(x => x.school === selectedOption.target.value && x.campuses.includes(this.state.campus))
  }
  classChanged= selectedOption => {
    this.setState({ class : selectedOption.target.value });
  }

  periodChanged= selectedOption => {
    this.setState({ educationPeriod : selectedOption.target.value });
  }

  
  
  handleSubmit = e => {
    e.preventDefault()


    this.setState({loading : true});

    if(this.state.paymentSessionId === undefined || this.state.paymentSessionId.length === 0)
    {

    }
    else
    {

    }

    const { number , cvc, expiry, cardHolderName, amount } = this.state;

    const prepareData = {
      cardNumber: removeSpaces(this.state.number),
      cvv: cvc,
      expireMonth: this.state.expiry.split('/')[0],
      expireYear: '20' + this.state.expiry.split('/')[1],
      cardHolderName,
      saveCreditCard: false,
      cardPoint: 0 };

    const data = this.state;
    
    const paymetRequestData = {
      cardNumber : maskCardNumber(removeSpaces(this.state.number)) ,
      cardHolderName: this.state.cardHolderName,
      phone: this.state.phone,
      email: this.state.email,
      description: this.state.description,
      amount: this.state.amount.replace(/\./g, ''),
      isAgreementConfirm: false,
      paymentSessionId: this.state.paymentSessionId,
      name: this.state.name,
      surname: this.state.surname,
      campus : this.state.campus,
      school : this.state.school,
      class : this.state.class,
      period : this.state.period,
      studentTckn : this.state.studentTckn,
      userId: null,
      campaignId: null,      
      paymentTab: 2,
      tenantPosId: 'BBF67A4D-7623-41CB-1D9A-08DA843357B3', 
      currencyDefinitionId: localStorage.getItem('currentCurrencyValue'),
      paymentSetPosId: "efbe9024-0ea7-44e8-68d6-08da84479543",
      use3D: true,
      installment: 1,
      studentName : this.state.studentName,
      studentSurname : this.state.studentSurname,
      payerTckn : this.state.payerTckn,
      educationPeriod : this.state.educationPeriod,
      currencyCode : localStorage.getItem('currentCurrencyCode'),
     };


    postPrepare(prepareData).then(response => {   
      if(response.status != 200)
      {
        if(response.response.data.errors[0].code === null)
      {  
        this.setState({loading : false});        
        this.error(i18n.t('msg.ErrorCreated') , i18n.t('msg.unknownException') )
      } 
      else
      {
        this.setState({loading : false});
        this.error(i18n.t('msg.ErrorCreated') , i18n.t('msg.' + response.response.data.errors[0].code) )
      }  
    }
      else{        
        postPayment(paymetRequestData).then(response => {
          if (response.status != 200)        
            console.log(response.response);
          else {
          if (!response?.data?.redirectUrl)    
          {   
            if(response?.data?.errorCode === null)
            {   
              this.setState({loading : false});       
              this.error(i18n.t('msg.ErrorCreated')  , i18n.t('msg.unknownException') )
            } 
            else
            {
              this.setState({loading : false});
              this.error(i18n.t('msg.ErrorCreated')  , i18n.t('msg.' + response?.data?.errorCode) )
            }   
            console.log(response?.data?.errorMessage);
          }    
          else {  
            this.setState({loading : false});   
            console.log(response.data.redirectUrl);      
            localStorage.setItem("redirectUrl", response.data.redirectUrl);  
            this.setState({redirectUrl : response.data.redirectUrl});
            this.setState({referenceNumber : response.data.referenceNumber});
            localStorage.setItem("referenceNumber", response.data.referenceNumber);  
            this.threeDProcess.start(response?.data.redirectUrl, response?.data.referenceNumber);
            setTimeout(()=>{
            this.checkResult();
            } , 15000);      
          }    
        }   
          console.log(response);  
    
        }).catch(error => {
          console.log(error);
        }) 
      }      
    }).catch(error => {      
      console.log(error)
    });;
    
    this.form.reset();
    this.setState({ number: "", cardHolderName: "", cvc: "", expiry: "" })   
  }

  opendialog()
  {
    setTimeout(()=>{
      if  (this.state.redirectUrl != null && this.state.redirectUrl.length > 0)
      {        
        this.dialog.open(PaymentModalComponent  , {
        height: '600px',
        width: '800px',
      });
      }
      else
      {
        this.opendialog()
      }      
  },2000)
  }

  checkResult()
  {
    setTimeout(()=>{
      try{        
        paymentResult(this.state.referenceNumber).then(response => {
        if(response != null && response.data != null && (response.data.transactoinStatusId == 1 || response.data.transactoinStatusId == 2 || response.data.transactoinStatusId == 4 ))
        {
            this.setState({ tryCount : this.state.tryCount + 1} )  
            if(response.data.transactoinStatusId == 1 || response.data.transactoinStatusId == 2 )
            {
                console.log("ödeme sonucu alındı") ;
                <Navigate to="/success" replace={true} />
                this.threeDProcess.close();
                if(response.data.transactoinStatusId == 1)
                {
                    localStorage.setItem("transactionResult" , 1);
                    localStorage.setItem("orderReference" , response.data.orderReference);
                    localStorage.setItem("provisionNumber" , response.data.provisionNumber);
                    localStorage.setItem("studentTckn" , response.data.studentTckn);
                    localStorage.setItem("studentName" , response.data.studentName);
                    localStorage.setItem("studentSurname" , response.data.studentSurname);
                    localStorage.setItem("educationPeriod" , response.data.educationPeriod);
                    localStorage.setItem("processAmount" , response.data.processAmount);
                    console.log("checkresult bitti"); 
                    window.location.reload()
                }
                else if (response.data.transactoinStatusId == 2)
                {        
                    localStorage.setItem("transactionResult" , 2);                    
                    localStorage.setItem("errorMessage" , response.data.errorMessage);  
                    window.location.reload()
                }
            }
            else if (this.state.tryCount > 20)
            {
                this.threeDProcess.close();
                this.setState({tryCount : 0 })  
            }
            else
            {
                this.checkResult()
            }
        }           

      }).catch(error => {
        console.log(error);
      });  
      }
      catch
      {
      }       
  }
   ,3000)

  }
 
  render() {
    const { cardHolderName, number : number, expiry, cvc, focused , getFieldDecorator } = this.state;
    return (
      <>
       <div key='Payment' className='payment-header' >    
        <div className='payment-container'>
          
            <form ref={c => (this.form = c)} onSubmit={this.handleSubmit}>
              
            <div className='container-card'>
              <div className='left-container-card'>
              <h4 className='group-header'>{i18n.t('lbl.enterAmount')}</h4>
              <div className='input-group input-group-icon'>
                <input
                  type='text'
                  name='amount'
                  placeholder='0,00'
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  //pattern="[0-9]"
                  value={this.state.amount}
                />               
                <div className="input-icon" style={{fontWeight:'bold'}}>{localStorage.getItem('currentCurrencySymbol')}</div>
                </div>
              <div className='input-group input-group-icon'>
                <input
                  type='text'
                  name='number'
                  placeholder={i18n.t('lbl.cardNumber')} 
                  pattern='[\d| ]{16,22}'
                  maxLength='19'
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  value={this.state.number}
                />
                 <div className="input-icon"><FontAwesomeIcon icon={faCreditCard} /></div>
                </div>           
                <div className='input-group-icon col-half'>
                <input
                    type='text'
                    name='cardHolderName'
                    placeholder={i18n.t('lbl.cardHolderFullName')} 
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    value={this.state.cardHolderName}
                  />                  
                 <div className="input-icon"><FontAwesomeIcon icon={faUserFriends} /></div>
                </div>
                <div className='col-four'>

                  <input
                    type='text'
                    name='expiry'
                    placeholder={i18n.t('lbl.expiryShort')} 
                    pattern='\d\d/\d\d'
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    value={this.state.expiry}
                  />
                </div>
                <div className='col-four-last'>
                  <input
                    type='tel'
                    name='cvc'
                    placeholder={i18n.t('lbl.cvc')} 
                    pattern='\d{3}'
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    value={this.state.cvc}
                  />
                </div>
            </div>
            <div className='right-container-card'>
                <Card
                  number={number}
                  name={cardHolderName}
                  expiry={expiry}
                  cvc={cvc}
                  focused={focused}
                  callback={this.handleCallback}
                  placeholders={{ name: i18n.t('lbl.cardHolderName') }}
                  
                />
              </div>
              </div>
             <h4 className='group-header'>{i18n.t('pay.schoolInformation')} </h4>
        
             <div className='input-group input-group-select'>        
                <select
                      required
                      className="select-group"
                      name="campus"
                      id="campusselect"
                      defaultValue={0}
                      // value={this.campuses[this.state.campus].value}
                      onChange={(e) => { this.campusChanged(e) }}
                    >
                      <option value="0" key="0">{i18n.t('pay.selectChoose')} </option>
                      {this.campuses?.map((c) => (
                        <option value={c.value} key={c.value}>
                          {c.label}
                        </option>
                      ))}
                </select>
                <div className="input-select-label">{i18n.t('pay.campus')}</div>
                  {/* <Select 
                  options={this.campuses}
                 placeholder={i18n.t('pay.campus')}  
                 id="campusselect" 
                 name='campus'
                 required           
                 onChange={this.campusChanged}      
                 value= {this.campuses[this.state.campus]}  
                 className='select-group'
                 />   */}
             </div>
             <div className='input-group input-group-select'>       
                <select
                      required
                      className="select-group"
                      name="school"
                      id="schoolselect"
                      defaultValue={0}
                      // value={this.campuses[this.state.campus].value}
                      onChange={(e) => { this.schoolChanged(e) }}
                    >
                      <option value="0" key="0">{i18n.t('pay.selectChoose')}</option>
                      {this.schools?.map((c) => (
                        <option value={c.value} key={c.value}>
                          {c.label}
                        </option>
                      ))}
                </select>
                <div className="input-select-label">{i18n.t('pay.school')}</div>                
              </div> 
             <div className='input-group input-group-select'>                  
                <select
                      required
                      className="select-group"
                      name="class"
                      id="classselect"
                      defaultValue={0}
                      // value={this.campuses[this.state.campus].value}
                      onChange={(e) => { this.classChanged(e) }}
                    >
                      <option value="0" key="0">{i18n.t('pay.selectChoose')} </option>
                      {this.classes?.map((c) => (
                        <option value={c.value} key={c.value}>
                          {c.label}
                        </option>
                      ))}
                </select>
                <div className="input-select-label">{i18n.t('pay.class')}</div>                
                </div>
             <div className='input-group input-group-select'>                    
                <select
                      required
                      className="select-group"
                      name="period"
                      id="periodselect"
                      // value={this.campuses[this.state.campus].value}
                      onChange={(e) => { this.periodChanged(e) }}
                    >
                      <option value="0" key="0">{i18n.t('pay.selectChoose')} </option>
                      {this.periods?.map((c) => (
                        <option value={c.value} key={c.value}>
                          {c.label}
                        </option>
                      ))}
                </select>
                
                <div className="input-select-label">{i18n.t('pay.period')}</div>                
              </div> 
    
            <div className='container-card'>
              <div className='left-Information'>                
              <h4 className='group-header'>{i18n.t('pay.contactStudentInformation')}</h4>
                <div className='input-group input-group-icon'>
                  <input
                    type='text'
                    name='studentName'
                    placeholder={i18n.t('lbl.name')} 
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    value={this.state.studentName}
                  />
                  <div className="input-icon"><FontAwesomeIcon icon={faUserFriends} /></div>
                </div>
                <div className='input-group input-group-icon'>
                  <input
                    type='text'
                    name='studentSurname'
                    placeholder={i18n.t('lbl.surname')} 
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    value={this.state.studentSurname}
                  />
                  <div className="input-icon"><FontAwesomeIcon icon={faUserFriends} /></div>
                </div>
                <div className='input-group input-group-icon'>
                  <input
                    type='text'
                    name='studentTckn'
                    placeholder={i18n.t('lbl.tcknLong')} 
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    maxLength="11"
                    value={this.state.studentTckn}
                  />
                  <div className="input-icon"><FontAwesomeIcon icon={faCheck} /></div>
                  </div>
              </div>
              <div className='right-Information'>
              <h4 className='group-header' >{i18n.t('pay.contactPayerInformation')} </h4>
                <div className='input-group input-group-icon'>

                  <input
                    type='text'
                    name='email'
                    placeholder={i18n.t('lbl.email')} 
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    value={this.state.email}
                  />
                  <div className="input-icon"><FontAwesomeIcon icon={faMailReply} /></div>
                </div>
                <div className='input-group input-group-icon' >

                <input
                    type='text'
                    name='phone'
                    placeholder={i18n.t('lbl.phone')} 
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    value={this.state.phone}
                  />
                  <div className="input-icon"><FontAwesomeIcon icon={faPhone} /></div>                
                </div>
                <div className='input-group input-group-icon'>

                  <input
                    type='text'
                    name='payerTckn'
                    placeholder={i18n.t('lbl.tcknLong')} 
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    maxLength="11"
                    value={this.state.payerTckn}
                  />
                  <div className="input-icon"><FontAwesomeIcon icon={faCheck} /></div>
              </div>
              </div>
              </div>                        
              <h4 className='group-header'>{i18n.t('pay.contactAdditionalInformation')} </h4>
              <div className='input-group input-group-icon'>
                <input
                  type='text'
                  name='description'
                  placeholder={i18n.t('lbl.description')} 
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  value={this.state.description}
                />
                <div className="input-icon"><FontAwesomeIcon icon={faInfo} /></div>
              </div>             

               {!this.state.loading &&
                <div className='form-actions'>             
                  <div className='form-actions-col-spec'>
                  <div className='cancel-button' ><a className='cancel-link' href='https://www.mef.k12.tr/'>{i18n.t('btn.cancel')}</a></div> 
                  </div>   
                  <div className='form-actions-col-spec' disabled={this.state.isPayable} >
                      <button className='submit-button' disabled={this.state.isPayable} >{i18n.t('btn.pay')} </button>
                  </div>
                </div>
                }
                <div>
                <p><br></br><br></br><br></br></p>
              </div>
            </form>            
        </div>    
      </div>
      <CreditCard3D ref={el => this.threeDProcess = el} />  
      <NavigateResult/>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.state.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </>
    )
  }
}


export default Pay;


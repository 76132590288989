function clearNumber(value = '') {
  return value.replace(/\D+/g, '')
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value
  }

  const issuer = Payment.fns.cardType(value)
  const clearValue = clearNumber(value)
  let nextValue

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`
      break
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`
      break
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
      break
  }

  return nextValue.trim()
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value)
  let maxLength = 3

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number)
  }

  return clearValue.slice(0, maxLength)
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value)

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
  }

  return clearValue
}

export function  formatCurrency (value) {
  const cleanValue = value.replace(/[^\d,]/g, '');

  // Onaylanmış bir ondalık ayracı (virgül) eklemek için düzenle
  const parts = cleanValue.split(',');
  let integerPart = parts[0];
  let decimalPart = parts[1] ? ',00' + parts[1].substring(0, 2) : '';

  // Binlik ayraçları ekle
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Sonucu birleştir
  return integerPart + decimalPart;
}

export function formatValue (type, value) {
  console.log(type);
  console.log(value);
  switch (type) {
    case 'iban':
      return value?.replace(/(.{4})/g, '$1 ').trim();
    case 'stringNumber':
      return value?.replace(/[^0-9]/, '');
    case 'phone':
      value = value ? value.toString() : '';
      return value.length < 2 ? '0' : (value.substr(0, 1) !== '0' ? '0' : '') + value.replace(/[^0-9]/, '');
    case 'amount':
      const stringValue = (value && value.toString()) || '';
      const preTemp = stringValue.split(stringValue.includes(',') ? ',' : '.');
      const hasSign = stringValue[0] === '-' || stringValue[0] === '+';
      const hasComma = stringValue?.includes(',') || stringValue?.includes('.');
      let _v;
      let _sign = "";
      let temp = `${preTemp[0] && !isNaN(preTemp[0]) ? parseFloat(preTemp[0]?.replace(',', '.')) : ''}${preTemp[1] ? `.${preTemp[1]}` : ''}`;

      if (hasSign) {
        _sign = stringValue.substr(0, 1);
        value = stringValue.slice(1);
        if (stringValue[0] !== '+') temp = temp.slice(1);
      }
      _v = value ? temp?.replace('.', ',')?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")?.split(',') : value;;
      _v = (_v || _sign) && `${_sign}${_v?.[0] || ''}${hasComma ? ',' : ''}${(_v[1]) ? _v[1] : ''}`;
      return _v;
    case 'cc-expiry':
      return formatExpirationDate(value);
    case 'cc-number':
      return formatCreditCardNumber(value);
    default:
      return value;
  }
};

export default formatValue;

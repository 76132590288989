import React ,  { useState} from 'react'
import Button from '@mui/material/Button'
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Tooltip } from '@mui/material';
import { getExcelTransaction } from '../services';
import { useSelector } from 'react-redux';


const ExportExcel = ({fileName}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;chartset-UTF-8';
    const fileExtension = '.xlsx'; 
    
    const filteredData = useSelector(state => state.transaction.filteredData);


    const exportToExcel = async () => {

        const educationPayments = filteredData.filter(row => row.activityBranch === null);
        const activitiesPayments = filteredData.filter(row => row.activityBranch !== null );

        let newDate = new Date()       
        
        const ws1 = XLSX.utils.json_to_sheet(educationPayments , []);
        const ws2 = XLSX.utils.json_to_sheet(activitiesPayments, []);
        const wb = {Sheets:{'OkulKayit':ws1 , 'Etkinlik':ws2}, SheetNames :['OkulKayit' , 'Etkinlik']};
        const excelBuffer = XLSX.write(wb,{bookType:'xlsx' , type:'array'});
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data , fileName + newDate.getTime() + fileExtension);
    }

    return(
        <>
             <Tooltip>
                <Button variant='contained'
                    onClick={(e) => exportToExcel(fileName)}
                    color='primary'
                    style={{cursor:'pointer' , fontSize:14 , width:200 , margin:5 , height:35}}
                
                >Excel Oluştur    
                </Button>
             </Tooltip>
        </>
    )

}

export default ExportExcel;
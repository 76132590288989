import React, { Component } from 'react'
import { Modal } from 'antd';
import {Link , Navigate } from 'react-router-dom';

export default class CreditCard3D extends Component {

    state = {
        visible: false,
        redirectUrl: null
      }

      start = (redirectUrl) => {
        this.toggleModal(true, redirectUrl);       
      }

      close = () => 
      {
        this.toggleModal(false);
      }

  toggleModal = (visible, redirectUrl = null) => this.setState({ visible, redirectUrl })

  

  render() {
    const { visible, redirectUrl } = this.state;
    return (
      <div>
        <Modal
          {...{
            centered: true,
            visible,
            footer: null,
            width: "85%",
            onCancel: () => this.toggleModal(false),
            className: "three-d__modal",
            maskClosable: false,
            maskStyle: { backgroundColor: 'rgba(0,0,0,.7)' },            
          }}
        >
          <iframe src={redirectUrl} title="3D" />
        </Modal>
        
      </div>
    )
  }
}

import React, { useState , useEffect ,  useCallback, useMemo, useRef } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import { getTransaction } from '../services';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../Css/ag-grid.css';
import { CSVLink } from 'react-csv';
import ExportExcel from '../Components/ExcelExport';
import { DatePicker } from 'antd';
import ReactDatePicker from 'react-datepicker';
import FilterComponent from '../Components/FilterComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../stores/transactionSlice';

export default function Transactions() {

    if(localStorage.getItem('isLogin') === null || localStorage.getItem('isLogin').length === 0 || localStorage.getItem('isLogin') === 'false')
    {
        return ""
    }
    const [startDate, setStartDate] = useState( `${new Date().getMonth() + 1}.${new Date().getDate()}.${new Date().getFullYear()}`);
   

    const dispatch = useDispatch(); 
    const filters = useSelector(state => state.filter.currentFilter);
    const filteredData = useSelector(state => state.transaction.filteredData);

    useEffect(() => {        
        getTransaction().then(response => {
            dispatch(setData(response.data));
            }).catch(error => {
                console.log(error);
              });;            
     }, []);
    

     const csvHeaders2 = [
        { label: 'Öğrenci Ad Soyad', key: 'studentNameSurName' },
        { label: 'Öğrenci TCKN', key: 'studentTckn' },
        { label: 'İşlem Tarihi', key: 'transactionDate' },
        { label: 'Durum', key: 'transactionStatus' },
        { label: 'Taksit', key: 'installment' },
        { label: 'Tutar', key: 'processAmount' },
        { label: 'Para Birimi', key: 'currencyCode' },
        { label: 'Kart Sahibi', key: 'cardHolderName' },
        { label: 'Kart No', key: 'creditCardNumber' },
        { label: 'Provizyon No', key: 'provisionNumber' },
        { label: 'Referans No', key: 'referenceNumber' },
        { label: 'Email', key: 'email' },
        { label: 'Telefon', key: 'phone' },
        { label: 'Açıklama', key: 'description' },
        { label: 'Kampüs', key: 'campus' },
        { label: 'Okul', key: 'school' },
        { label: 'Sınıf', key: 'class' },
        { label: 'Dönem', key: 'educationPeriod' },
        { label: 'Ödeyen TCKN', key: 'payerTckn' },
        { label: 'Etkinlik Sınıfı', key: 'activityCategory' },
        { label: 'Branş', key: 'activityBranch' },
        { label: 'Hata Kodu', key: 'vPosErrorCode' },
        { label: 'Hata Mesajı', key: 'vPosErrorMessage' }
      ];


    const [columnDefs] = useState([
        { field: 'transactionDate'  , headerName: 'İşlem Tarihi', resizable: true, width: 120, filter: 'agDateColumnFilter'},
        { field: 'transactionStatus' , headerName: 'İşlem Durumu', width: 140, resizable: true, filter: 'agTextColumnFilter'},
        { field: 'installment' , headerName: 'Taksit' , width: 80 , resizable: true},
        { 
            field: 'processAmount' , 
            headerName: 'Tutar' , 
            width: 100,  
            sortable: true, 
            resizable: true, 
            
        },
        { field: 'currencyCode' , headerName: 'Doviz' , width: 80, resizable: true},
        { field: 'studentNameSurName' , headerName: 'Öğrenci Ad Soyad' , width: 170 , filter: 'agTextColumnFilter', resizable: true, initialPinned: 'left'},
        { field: 'studentTckn' , headerName: 'Öğrenci TCKN' , width: 150, resizable: true, filter: 'agTextColumnFilter', initialPinned: 'left'},
        { field: 'cardHolderName' , headerName: 'Kart Sahibi' , width: 150, resizable: true},
        { field: 'creditCardNumber' , headerName: 'Kredi Kartı' , width: 150, resizable: true},
        { field: 'provisionNumber' , headerName: 'Provizyon No' , width: 120, resizable: true},
        { field: 'referenceNumber' , headerName: 'Referans' , width: 150, resizable: true},
        { field: 'email' , headerName: 'Email' , width: 150, resizable: true, filter: 'agTextColumnFilter'},
        { field: 'phone' , headerName: 'Telefon' , width: 150, resizable: true},
        { field: 'description' , headerName: 'Açıklama' , width: 250 , resizable: true},       
        { field: 'campus' , headerName: 'Kampüs' , width: 150, resizable: true},
        { field: 'school' , headerName: 'Okul' , width: 150, resizable: true},
        { field: 'class' , headerName: 'Sınıf' , width: 100, resizable: true},
        { field: 'educationPeriod' , headerName: 'Dönem' , width: 150, resizable: true},
        { field: 'payerTckn' , headerName: 'Ödeyen TCKN' , width: 150, resizable: true},
        { field: 'activityCategory' , headerName: 'Etkinlik Sınıfı' , width: 150, resizable: true},
        { field: 'activityBranch' , headerName: 'Branş' , width: 150, resizable: true},
        { field: 'vPosErrorCode' , headerName: 'Hata Kodu' , width: 120, resizable: true},
        { field: 'vPosErrorMessage' , headerName: 'Hata Mesajı' , width: 200, resizable: true}
    ])
 
  return (
    <div className="ag-theme-alpine" style={{height: 540}}>
    <FilterComponent />   
    <AgGridReact
        rowData={filteredData}
        columnDefs={columnDefs}
        pagination={true}     
        paginationPageSize={10}
      >
    </AgGridReact>
    </div>
  )
}

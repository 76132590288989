import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    
    currentCurrency : { 
        value: localStorage.getItem('currentCurrencyValue') ,
        label: localStorage.getItem('currentCurrencyDescription') ,
        symbol : localStorage.getItem('currentCurrencySymbol') ,
        code : localStorage.getItem("currentCurrencyCode")
    },


}

const currencies = createSlice({
    name:'currencies',
    initialState,
    reducers : {
        setCurrency : (state , action) => {
            state.currentLang = action.payload
            localStorage.setItem("currentCurrencyValue" , action.payload.value)
            localStorage.setItem("currentCurrencyDescription" , action.payload.label)
            localStorage.setItem("currentCurrencySymbol" , action.payload.symbol)
            localStorage.setItem("currentCurrencyCode" , action.payload.code)
        },
    }

})

export const {setCurrency} = currencies.actions

export default currencies.reducer;
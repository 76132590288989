import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    
    currentFilter : { 
        transactionStatus: '',
        startdate : '',
        endDate : '',
        campus : '',
        school : '',
        class : '',
        educationPeriod : '',
        activityBranch : '',
        activityCategory : '',
        fullTextSearch : ''
    }

}

const filter = createSlice({
    name:'filter',
    initialState,
    reducers : {
        setFilter: (state, action) => {
            state.currentFilter = { ...state.currentFilter, ...action.payload }
          },
    }

})

export const {setFilter} = filter.actions

export default filter.reducer;
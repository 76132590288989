import React from 'react'
import {Navigate } from 'react-router-dom';


class NavigateResult extends React.Component {   
  
    render() {
      let navigate;
      if ( localStorage.getItem("transactionResult") == 1) {
        navigate =  <Navigate to="/success" replace={true}  />;
        

      } 
      else if ( localStorage.getItem("transactionResult") == 2) {
        navigate = <Navigate to="/fail" replace={true} />;
   
      }     
  
      return (
        <div>          
          {navigate}
        </div>
      );
    }
  }

  export default NavigateResult;
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik } from "formik";
import * as Yup from "yup";
import '../login.css';
import TransactionNavigate from '../Components/TransactionNavigate';

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email adresi zorunludur")
    .email("Hatalı email adres formatı"),
  password: Yup.string()
    .required("Şifre alanı zorunludur")
    .min(8, "Şifre en az 8 karakterli olmalıdır")
});


export default function Login() {

  const gotoTransaction = () => {
    localStorage.setItem('gotoTransaction' , 1)       
    window.location.reload()     
  }

  return (
    <>
    <Formik
      validationSchema={schema}
      initialValues={{ email: "", password: "" }}
      onSubmit={(values) => {
        if((values.email === 'turamane@mef.k12.tr' && values.password === 'Turaman1234!') 
        || (values.email === 'ekino@mef.k12.tr' && values.password === 'Ekin1234!') 
        || (values.email === 'kubilays@mef.k12.tr' && values.password === 'Kubilay1234!') 
        || (values.email === 'erens@mef.k12.tr' && values.password === 'Eren1234!')        
        )
          {        
            localStorage.setItem("gotoTransaction" , 1) 
            localStorage.setItem("isLogin", 'true');  
            gotoTransaction();
          }
          else{
            alert('Hatalı kullanıcı adı veya sifre');
          }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <div className="login">
          <div className="login_form">
         {/* Passing handleSubmit parameter tohtml form onSubmit property */}
            <form noValidate onSubmit={handleSubmit}>
              <span>Üye Girişi</span>
            {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
              <input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="Email adresi giriniz"
                className="form-control inp_text"
                id="email"
              />
              {/* If validation is not passed show errors */}
              <p className="error">
                {errors.email && touched.email && errors.email}
              </p>
               {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
              <input
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                placeholder="Şifre Giriniz"
                className="form-control"
              />
               {/* If validation is not passed show errors */}
              <p className="error">
                {errors.password && touched.password && errors.password}
              </p>
              {/* Click on submit button to submit the form */}
              <button type="submit">Giriş Yap</button>
            </form>
          </div>
        </div>
      )}
    </Formik>    
    <TransactionNavigate/>
  </>
  )
}
